<template>
  <UiPopup
    v-model="modelValue"
    title="Meeting outcome"
    :description="`Several (${count}) scheduled meetings with leads have ended. Please fill in the meeting outcomes for each lead in activities table by clicking on “Pending”.`"
    primary-button-text="Open in a table"
    secondary-button-text=""
    size="extraSmall"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="input"
  >
    <img src="/assets/images//meeting_outcome_reminders.webp" alt="outcomes" class="mx-auto my-6 w-72" />
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  count: number
}

defineProps<Props>()

const input = () => {
  emits('input')
  modelValue.value = false
}
</script>

<style scoped></style>
